// import InboxTable from '../../screens/Main/Company/Inbox/InboxTable/InboxTable';

import React, { lazy } from 'react';
const Companies = lazy(() => import('../../screens/Main/Company/Companies/Companies'));
const InboxTable = lazy(() => import('../../screens/Main/Company/Inbox/InboxTable/InboxTable'));

const SubNavMenu = [
  {
    path: 'companies',
    element: <Companies />
  },
  {
    path: 'inbox',
    element: <InboxTable />
  },
  {
    path: 'draft',
    element: <div>I am draft</div>
  },
  {
    path: 'inprocess',
    element: <div>I am in process</div>
  },
  {
    path: 'rejected',
    element: <div>I am rejected</div>
  },
  {
    path: 'pendingapproval',
    element: <div>I am pending approval</div>
  },
  {
    path: 'publish',
    element: <div>I am publish</div>
  },
  {
    path: 'newprojecttask',
    element: <div>I am new project task</div>
  }
];

export default SubNavMenu;
