import { configureStore } from '@reduxjs/toolkit';

import AuthReducer from '../../library/common/reducers/AuthReducer';

const store = configureStore({
  reducer: {
    auth: AuthReducer
  }
});

export default store;
