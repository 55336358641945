/* eslint-disable  */
import ajax from '../utils/ajaxHelper';
import { getTokenFromStorage, removeAuthUserFromStorage } from '../utils/common';

const baseUrl = process.env.REACT_APP_API_URL;

export const getRequest = async (url, callback) => {
  const apiUrl = baseUrl + url;
  await ajax
    .get(apiUrl, {
      networkParams: { showLoader: false },
      headers: getToken()
    })
    .then((res) => {
      callback(res);
    })
    .catch((err) => {
      if (err?.response?.status == '401') {
        unAuthorizedAccess();
      }
    });
};

export const postRequest = async (url, data, callback) => {
  const apiUrl = baseUrl + url;
  await ajax
    .post(apiUrl, data, {
      networkParams: { showLoader: false },
      headers: getToken()
    })
    .then((res) => {
      if (res?.data?.IsSuccess) {
        callback(res?.data);
      } else {
        callback(res);
      }
    })
    .catch((err) => {
      if (err?.response?.status == '401') {
        unAuthorizedAccess();
      }
    });
};

const getToken = () => {
  let reqHeaders = {};
  let authToken = getTokenFromStorage();
  if (authToken) {
    reqHeaders = {
      Authorization: 'Bearer ' + authToken
    };
  }
  return reqHeaders;
};

const unAuthorizedAccess = () => {
  // removeAuthUserFromStorage();
  // window.location = '/';
}
