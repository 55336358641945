export const removeAuthUserFromStorage = () => {
  window.localStorage.removeItem('authToken');
};

export const setTokenToStorage = (token) => {
  window.localStorage.setItem('authToken', token);
};

export const defaultSnackBarState = {
  show: false,
  type: 'success',
  message: '',
  vertical: '', 
  horizontal: ''
};

export const getTokenFromStorage = () => {
  const token = window.localStorage.getItem('authToken');
  return token;
};
