import React, { lazy } from 'react';
import SubNavMenu from './subNav';
import { Outlet } from 'react-router-dom';
const Home = lazy(() => import('../../screens/Main/Home/Home'));

const ArrayForNav = ['projects', 'tenders', 'contract', 'company']

const superAdminNav = ArrayForNav?.map((path) => {
  return {
    path: path,
    element: <Outlet />,
    children: SubNavMenu,
  }
})

export default superAdminNav;
